import 'nodelist-foreach-polyfill';
import AnchorScroll from './modules/_anchorScroll.js';
import ScrollFx from './modules/_scrollFx.js';
import Slideshow from './modules/_slideshow.js';
import Header from './modules/_header.js';
import Index from './modules/_index.js';

class App {
	constructor() {
		this.anchorScroll = new AnchorScroll();
		this.scrollFx = new ScrollFx();
		this.slideshow = new Slideshow();
		this.header = new Header();
		this.index = new Index();
	}
}

document.addEventListener('DOMContentLoaded', () => {
	window.isTouch = false;
	if( navigator.userAgent.indexOf('iPhone') > 0        ||
		navigator.userAgent.indexOf('iPod') > 0          ||
		navigator.userAgent.indexOf('iPad') > 0          ||
		navigator.userAgent.indexOf('Windows Phone') > 0 ||
		navigator.userAgent.indexOf('Android') > 0 ) {
		window.isTouch = true;
		document.body.classList.add('touch');
	}
	let app = new App();
}, false);