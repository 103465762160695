export default class Slideshow{
	constructor(){
		document.querySelectorAll(".slideshow").forEach((slideshow,index)=>{
			this.initSlideshow(slideshow,index);
		});
	}

	initSlideshow(slideshow,index){
		const viewport = slideshow.querySelector('.slideshow__viewport');
		const container = slideshow.querySelector('.slideshow__container');
		const slides = slideshow.querySelectorAll(".slideshow__slide");
		const pages = slideshow.querySelectorAll(".slideshow__pager__page");
		const num = slides.length;
		let position = 0;
		let isMoving = false;

		const start = window.isTouch ? "touchstart" : "mousedown";
		const move = window.isTouch ? "touchmove" : "mousemove";
		const end = window.isTouch ? "touchend" : "mouseup";
		const leave = window.isTouch ? "touchleave" : "mouseleave";

		let isDragging = null;
		let startX,startY,moveX,moveY = 0;
		const autoSlideDuration = 4000;
		let autoSlideTimer = null;

		function mouseStartHandler(e){
			e.stopPropagation();
			isDragging = false;
			startX = e.pageX;
			startY = e.pageY;
		}
		function mouseMoveHandler(e){
			if(isDragging === null) return false;
			if(isDragging === true) e.preventDefault();
			moveX = e.pageX;
			moveY = e.pageY;
			if(isDragging === false){
				if(!window.isTouch || Math.abs(moveX - startX) > Math.abs(moveY - startY)){
					isDragging = true;
					container.classList.add("dragging");
					stopAutoSlide();
				}else{
					isDragging = null;
				}
			}
			if(isDragging){
				container.style.transform = "translateX(calc("+(-100*position)+"% + "+(moveX-startX)/2+"px))";
			}
		}
		function mouseEndHandler(e){
			if(isDragging === true) {
				if((moveX - startX) > 0){
					prev();
				}else{
					next();
				}
				container.classList.remove("dragging");
				container.removeAttribute("style");
			}
			isDragging = null;
		}

		function next(){
			//if(isMoving) return false;
			position = position+1;
			slide();
		}
		function prev(){
			//if(isMoving) return false;
			position = position-1;
			slide();
		}
		function slide(){
			//isMoving = true;
			if(position >= num){
				position = 0;
			}else if(position <= -1){
				position = num-1;
			}
			slideshow.setAttribute("data-position", position)
			startAutoSlide();
		}

		function stopAutoSlide(){
			if(autoSlideTimer){
				clearTimeout(autoSlideTimer);
				autoSlideTimer = null;
			}
		}
		function startAutoSlide(){
			stopAutoSlide();
			autoSlideTimer = setTimeout(next, autoSlideDuration);
		}

		pages.forEach(page=>{
			page.addEventListener("click", ()=>{
				//if(isMoving) return false;
				position = page.getAttribute("data-num")*1;
				slide();
			}, false);
		})
		viewport.addEventListener(start, mouseStartHandler, false);
		viewport.addEventListener(move, mouseMoveHandler, false);
		viewport.addEventListener(end, mouseEndHandler, false);
		viewport.addEventListener(leave, mouseEndHandler, false);

		startAutoSlide();
	}
}