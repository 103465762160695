export default class Index{
	constructor(){
		if(document.body.classList.contains("index")){
			this.initialize();
		}
	}

	initialize(){
		const video = document.querySelector(".index__firstview__hero__video video");
		const toggle = document.querySelector(".index__firstview__hero__video .togglesound");
		toggle.addEventListener("click", ()=>{
			video.muted = !video.muted;
			if(video.muted){
				toggle.classList.add("muted");
			}else{
				toggle.classList.remove("muted");
			}
		});
	}
}