export default class Header{
	constructor(){
		const header = document.querySelector(".header");
		const container = header.querySelector(".header__container");
		const content = header.querySelector(".header__content");
		const toggle = header.querySelector(".header__toggle");
		let isOpen = false;

		function toggleHeader(){
			if(isOpen){
				header.classList.remove("open");
				isOpen = false;
			}else{
				header.classList.add("open");
				isOpen = true;
			}
		}
		function headerResizeHandler(){
			content.style.height = window.innerHeight+"px";
		}

		toggle.addEventListener("click", toggleHeader, false);
		window.addEventListener("resize", headerResizeHandler, false);
		headerResizeHandler();	
	}
}