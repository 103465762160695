import anime from 'animejs/lib/anime.es.js';
export default class AnchorScroll{
	constructor(){
		const buttons = document.querySelectorAll('a[href^="#"]');
		//const gap = (window.innerWidth <= 1024) ? 0 : 80;
		const gap = 0;
		this.scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
		buttons.forEach((btn)=>{
			this.setupSmoothScroll(btn);
		});

		if(window.location.hash){
			const targetDom = document.querySelector(window.location.hash);
			if(targetDom){
				let targetPosition = 0;
				const rect = targetDom.getBoundingClientRect();
				const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
				targetPosition = rect.top + scrollTop - gap;
				setTimeout(()=>{
					this.scrollElement.scrollTop = targetPosition;
				},10);
			}
		}
	}

	setupSmoothScroll(btn){
		const href = btn.getAttribute("href");
		if(href.indexOf('#') !== 0) return false;

		btn.addEventListener('click', (e)=>{
			let targetPosition = 0;
			if(href.length > 1){
				const targetDom = document.querySelector(href);
				if(targetDom){
					// const gap = (window.innerWidth <= 1024) ? 0 : 80;
					const gap = 0;
					const rect = targetDom.getBoundingClientRect();
					const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
					targetPosition = rect.top + scrollTop - gap;
					anime({
						targets: this.scrollElement,
						scrollTop: targetPosition,
						duration: 750,
						easing: 'easeOutExpo'
					});
				}
			}
			
			e.preventDefault();
			e.stopPropagation();
			return false;
		}, false);
	}
}