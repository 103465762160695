export default class ScrollFx{
	constructor(){
		const fxTargets = [ ".scrollFx" ];
		const fxBlocks = Array.from(document.querySelectorAll(fxTargets.join())).map(block => {
			let obj = {
				dom: block,
				appear: false
			}
			return obj;
		});
		let threshold = window.innerHeight/4;

		function scrollHandler(){
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			fxBlocks.forEach((block) => {
				if(block.appear == false){
					const bound = block.dom.getBoundingClientRect();
					if((bound.top <= window.innerHeight - threshold || bound.y <= 0)){
						block.dom.classList.add('appear');
						block.appear = true;
					}
				}
			});
			if(scrollTop >= document.body.clientHeight-window.innerHeight){
				fxBlocks.forEach((block) => {
					if(block.appear == false){
						block.dom.classList.add('appear');
						block.appear = true;
					}
				});
				window.removeEventListener("scroll", scrollHandler);
				window.removeEventListener("resize", resizeHandler);
			}
		}
		function resizeHandler(){
			threshold = window.innerHeight/4;
			scrollHandler();
		}

		setTimeout(()=>{
			window.addEventListener("scroll", scrollHandler, false);
			window.addEventListener("resize", resizeHandler, false);
			resizeHandler();
		}, 300);
		
	}
}